import { ChecklistRatingResultDTO } from '@library/dto/checklist/checklist-rating-result.dto';
import { WorkmanshipDTO } from '@library/dto/checklist/defect/defects-workmanship.dto';
import { IMeasurementSampling } from '@library/dto/checklist/measure/simplified/measurement-sampling.interface';
import { SimplifiedMeasurementChecklistMeasuresDTO } from '@library/dto/checklist/measure/simplified/simplified-measurement-checklist-measures.dto';
import { PerProductAqlAndSamplingDTO } from '@library/dto/per-product-aql-and-sampling.dto';
import { PictureTagDTO } from '@library/dto/picture-tag.dto';
import { ProductCategoryDTO } from '@library/dto/product-category.dto';
import { SumRatingResultDTO } from '@library/dto/testschecklist/answer/sum-rating-result.dto';
import { CustomFieldInspectionAnswerPairDTO } from '@library/dto/workflow/custom-fields.dto';
import { WorkflowRatingGroupsConfigurationDTO } from '@library/dto/workflow/settings/workflow-rating-groups-configuration.dto';
import { WorkflowSumRatingConfigDTO } from '@library/dto/workflow/settings/workflow-sum-rating-config.dto';
import { CartonCalculationMethod } from '@library/dto-enums/carton-calculation-method.dto-enum';
import { ChecklistPublicationStatus } from '@library/dto-enums/checklist-publication-status.dto-enum';
import { DefectClassification } from '@library/dto-enums/defect-classification.dto-enum';
import { PictureSource } from '@library/dto-enums/picture-source.dto-enum';
import { IQpAddress } from '@library/models/address/qp-address.model';
import { EQpAQLInspectionLevel } from '@library/models/qp-aql.models';
import { IBrdInspectionReferenceSample } from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import {
  EInspectionResult,
  IWorkflowUserIdentity,
} from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import {
  EAbortedInspectionResult,
  EWorkflowTemplateTestImagesResultDisplayMode,
} from '@one/app/pages/brd/pages/workflow/shared/models/brd-workflow-template.models';
import { IEntity, IEntityConsultation } from '@one/app/pages/brd/shared/models/entity.model';
import { IDefectsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import { IProductVariances } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { ITestsChecklistDetails } from '@one/app/shared/models/checklist/tests-checklist.models';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import {
  IAttachmentData,
  IChecklistComment,
  IChecklistImageData,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import {
  IAbstractFixedSamplingSize,
  IWorkflowSamplingSize,
  WorkflowSamplingSizeType,
} from '@one/app/shared/models/sampling/sampling-size.models';
import { WorkflowIdType, WorkflowPathIdType } from '@one/app/shared/models/workflows/workflow.models';
import {
  EMeasurementOverallResultCalculationMethod,
  EWorkflowMeasurementProof,
  EWorkflowTemplateStepActionInspectionPreparationCalculationMethod,
} from '@one/app/shared/models/workflows/workflows-templates.models';
import { QimaOptionalType, QimaUuidType } from '@qima/ngx-qima';

export type WorkflowStepActionDefectsChecklistSamplingSizeType = IAbstractFixedSamplingSize &
  Pick<IWorkflowSamplingSize, 'products'> & {
    inspectionLevel?: EQpAQLInspectionLevel;
  };

export interface IWorkflow {
  id?: number;
  inspectionUuid?: string;
  name: string;
  aqlDefects?: AqlDefectsType;
  steps: IWorkflowStep[];
  globalRemark?: IChecklistComment;
  globalImages?: IChecklistImageData[];
  samplingSize?: WorkflowSamplingSizeType;
  findingsSummaryReview?: IWorkflowInspectionFindingsSummaryReview;
  pickedCartons?: number;
  /**
   * @description
   * ⚠ Can be NaN
   */
  totalCartons?: number;
  cartonCalculationMethod?: CartonCalculationMethod;
  /**
   * @deprecated Use `ratingResults` instead
   */
  ratingResult?: ChecklistRatingResultDTO;
  ratingResults?: ChecklistRatingResultDTO[];
  comment?: string;
  sumRatingResult?: SumRatingResultDTO; // 💡 Optional (it is filled only if the workflow has `sum rating` enabled)
  sumRatingConfig?: WorkflowSumRatingConfigDTO; // 💡 Optional (it is filled only if the workflow has `sum rating` enabled)
  settings?: IWorkflowSettings; // 💡 Optional (It is where all settings of Workflow should be populated. It's filled if there is an active setting)
  workmanship: WorkmanshipDTO[];
  perProductAqlAndSampling?: PerProductAqlAndSamplingDTO[];
  isAborted: boolean;
  pictureTags?: PictureTagDTO[];
}

export interface IWorkflowSettings {
  testImagesResultsDisplayMode: EWorkflowTemplateTestImagesResultDisplayMode;
  ratingGroupsConfiguration?: WorkflowRatingGroupsConfigurationDTO;
  abortedInspectionResult?: EAbortedInspectionResult;
  isCustomDefectsCreationAllowedForInspector: boolean;
}

export interface IWorkflowStep {
  id?: WorkflowIdType;
  name: string;
  actions: IWorkflowAction[];
  ratingResult?: ChecklistRatingResultDTO;
}

export interface IProductActualQuantities {
  productId: number;
  purchaseOrderProductId: QimaOptionalType<number>;
  produced: number;
  packed: number;
}

/**
 * @todo use better types because everything is optional but it should not be based on the {@link IWorkflowAction#type}
 */
export interface IWorkflowAction {
  id: WorkflowPathIdType;
  uuid: QimaUuidType;
  type: EWorkflowActionType;
  actionName?: string;
  testsChecklist?: ITestsChecklistDetails;
  defectsChecklist?: IDefectsChecklist;
  instructions?: string;
  documentId?: string;
  referenceSample?: IBrdInspectionReferenceSample;
  sampleCollection?: IWorkflowSampleCollection;
  measuresChecklists?: IProductVariances[];
  findingsSummaryReview?: IWorkflowInspectionFindingsSummaryReview;
  result?: EInspectionResult;
  ratingResult?: ChecklistRatingResultDTO;
  checks?: (WorkflowCheckType | WorkflowAuditCheckType)[];
  user?: IWorkflowUserIdentity;
  metadata?: IWorkflowActionMetadata;
  comment?: string;
  updatableByInspector?: boolean;
  calculationMethod?: EWorkflowTemplateStepActionInspectionPreparationCalculationMethod;
  recipients?: string[];
  ccRecipients?: string[];
  entity?: IEntity | IEntityConsultation;
  publicationStatus?: ChecklistPublicationStatus; // Only for type EWorkflowActionType.DEFECTS_CHECKLIST
  productCategories?: ProductCategoryDTO[]; // Only for type EWorkflowActionType.DEFECTS_CHECKLIST
  customFields?: CustomFieldInspectionAnswerPairDTO[]; // Only for type EWorkflowActionType.CUSTOM_FIELDS
  commentsMandatoryWhenNotApplicable?: boolean; // Only for type EWorkflowActionType.TESTS_CHECKLIST
  photosMandatoryWhenDefectsFound?: boolean; // Only for type EWorkflowActionType.DEFECTS_CHECKLIST
  commentsMandatoryWhenDefectsFound?: boolean; // Only for type EWorkflowActionType.DEFECTS_CHECKLIST
  canInspectionBeMarkedAsPending?: boolean; // Only for type EWorkflowActionType.FINDINGS_SUMMARY_REVIEW
  isInspectionMarkedAsPending?: boolean; // Only for type EWorkflowActionType.FINDINGS_SUMMARY_REVIEW
  text?: string; // For type EWorkflowActionType.ADDENDUM
  fileId?: string; // For type EWorkflowActionType.ADDENDUM
  productQuantities?: IProductActualQuantities[];
  version?: number;
  simplifiedMeasurement?: ISimplifiedMeasurementChecklist;
  requiredProofs?: EWorkflowMeasurementProof[]; // Only for type EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
  measurementOverallResultCalculationMethod?: EMeasurementOverallResultCalculationMethod; // Only for type EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
  isCommentRequiredWhenMarkedAsNA?: boolean; // Only for type EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
  expectedResult?: string; // Only for type EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
  pictures?: IChecklistImageData[]; // Only for type EWorkflowActionType.STARTING_PICTURES
  validatedByInspector?: boolean; // Only for type EWorkflowActionType.STARTING_PICTURES
  pictureSource?: PictureSource; // Only for type EWorkflowActionType.STARTING_PICTURES
}

export interface IWorkflowActionMetadata {
  filename: string;
  type: string;
  size: number;
}

/**
 * @deprecated use {@link WorkflowActionType} instead
 */
export enum EWorkflowActionType {
  // Legacy and front-end only
  PREPARATION = 'PREPARATION',
  INSPECTION_PREPARATION = 'INSPECTION_PREPARATION',
  PRODUCT_PICTURE = 'PRODUCT_PICTURE',
  INSTRUCTIONS = 'INSTRUCTIONS',
  DOCUMENT = 'DOCUMENT',
  TEMPLATE_DOCUMENT = 'TEMPLATE_DOCUMENT',
  TESTS_CHECKLIST = 'TESTS_CHECKLIST',
  DEFECTS_CHECKLIST = 'DEFECTS_CHECKLIST',
  MEASURES_CHECKLIST = 'MEASURES_CHECKLIST',
  SIMPLIFIED_MEASUREMENTS_CHECKLIST = 'SIMPLIFIED_MEASUREMENTS_CHECKLIST',
  COMPLETE = 'COMPLETE',
  DRAFT_REPORT = 'DRAFT_REPORT',
  SAMPLE_COLLECTION = 'SAMPLE_COLLECTION',
  REFERENCE_SAMPLE = 'REFERENCE_SAMPLE',
  INSPECTION_DETAILS = 'INSPECTION_DETAILS',
  FINDINGS_SUMMARY_REVIEW = 'FINDINGS_SUMMARY_REVIEW',
  REPORT = 'REPORT',
  SITE_LOCATION = 'SITE_LOCATION',
  ADD_RECIPIENTS = 'ADD_RECIPIENTS',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
  ADDENDUM = 'ADDENDUM',
  STARTING_PICTURES = 'STARTING_PICTURES',
}

export enum EWorkflowCheckType {
  INSPECTOR_ACKNOWLEDGMENT = 'INSPECTOR_ACKNOWLEDGMENT',
  FACTORY_ACKNOWLEDGMENT = 'FACTORY_ACKNOWLEDGMENT',
  INSPECTOR_SIGNATURE = 'INSPECTOR_SIGNATURE',
  FACTORY_SIGNATURE = 'FACTORY_SIGNATURE',
}

export enum EWorkflowAuditCheckType {
  AUDITOR_ACKNOWLEDGMENT = 'AUDITOR_ACKNOWLEDGMENT',
  FACTORY_ACKNOWLEDGMENT = 'FACTORY_ACKNOWLEDGMENT',
  AUDITOR_SIGNATURE = 'AUDITOR_SIGNATURE',
  FACTORY_SIGNATURE = 'FACTORY_SIGNATURE',
}

export enum EWorkflowPath {
  STEP = 0,
  ACTION = 1,
  PRODUCT = 2,
  PRODUCT_VARIANCE = 3,
  INDEX_SUFFIX = 1,
  INDEX_NOT_FOUND = -1,
  MIN_PATH_LENGTH = 2,
  TEST_CHECKLIST_ELEMENT = 2,
  TEST_CHECKLIST_ELEMENT_TEST = 3,
}

export interface IWorkflowAcknowledgmentCheck extends IWorkflowCheck {
  hasBeenAcknowledged: boolean;
  acknowledgedBy?: string;
}

export interface IWorkflowSignatureCheck extends IWorkflowCheck {
  signedBy?: string;
  documentId?: string;
}

export interface IWorkflowInspectorAcknowledgmentCheck extends IWorkflowAcknowledgmentCheck {
  type: EWorkflowCheckType.INSPECTOR_ACKNOWLEDGMENT;
}

export interface IWorkflowEntityAcknowledgmentCheck extends IWorkflowAcknowledgmentCheck {
  type: EWorkflowCheckType.FACTORY_ACKNOWLEDGMENT;
}

export interface IWorkflowInspectorSignatureCheck extends IWorkflowSignatureCheck {
  type: EWorkflowCheckType.INSPECTOR_SIGNATURE;
}

export interface IWorkflowEntitySignatureCheck extends IWorkflowSignatureCheck {
  type: EWorkflowCheckType.FACTORY_SIGNATURE;
}

export interface IWorkflowAuditorAcknowledgmentCheck extends IWorkflowAcknowledgmentCheck {
  type: EWorkflowAuditCheckType.AUDITOR_ACKNOWLEDGMENT;
}

export interface IWorkflowAuditEntityAcknowledgmentCheck extends IWorkflowAcknowledgmentCheck {
  type: EWorkflowAuditCheckType.FACTORY_ACKNOWLEDGMENT;
}

export interface IWorkflowAuditorSignatureCheck extends IWorkflowSignatureCheck {
  type: EWorkflowAuditCheckType.AUDITOR_SIGNATURE;
}

export interface IWorkflowAuditEntitySignatureCheck extends IWorkflowSignatureCheck {
  type: EWorkflowAuditCheckType.FACTORY_SIGNATURE;
}

export type WorkflowCheckType =
  | IWorkflowInspectorAcknowledgmentCheck
  | IWorkflowEntityAcknowledgmentCheck
  | IWorkflowInspectorSignatureCheck
  | IWorkflowEntitySignatureCheck;

export type WorkflowAuditCheckType =
  | IWorkflowAuditorAcknowledgmentCheck
  | IWorkflowAuditEntityAcknowledgmentCheck
  | IWorkflowAuditorSignatureCheck
  | IWorkflowAuditEntitySignatureCheck;

export interface IWorkflowCheckTemplate {
  actionId: string;
  type: EWorkflowCheckType | EWorkflowAuditCheckType;
}

export interface IWorkflowCheck {
  actionId: string;
  type: EWorkflowCheckType | EWorkflowAuditCheckType;
  checkDate?: Date;
  checkTimezone?: string;
}

export interface IWorkflowSampleCollection {
  address: IQpAddress;
  volume: number;
  unit: string;
  percentageOfDefectiveSamplesVolume: number;
  percentageOfValidSamplesVolume: number;
  instructions?: string;
  isCompleted: boolean;
}

/**
 * @description
 * This model is used in:
 * - the local storage database state (offline mode)
 * - the store state (ngrx)
 * - anywhere else in the app
 * Beware the impact of a model change on all those parts of the app.
 */
export interface IWorkflowInspectionFindingsSummaryReview {
  isConfirmed: boolean;
  canInspectionBeMarkedAsPending?: boolean;
  isInspectionMarkedAsPending?: boolean;
}

/**
 * @description
 * This model is used when submitting the confirmation on the API.
 * This model should only be used in the service performing the http call.
 * Follow strictly the backend object (naming + fields + types + optional or not).
 */
export interface IFindingsSummaryReviewConfirmationDTO {
  confirmationTime: Date;
  confirmationTimezone: string;
}

export interface IUpdateDefectData {
  quantity?: number;
  comment?: IChecklistComment;
  classification?: string;
  purchaseOrderProductId?: QimaOptionalType<number>;
}

export interface INewDefectInfo {
  name: string;
  type: string;
  stepId: string;
  classification: DefectClassification;
  purchaseOrderProductId?: number;
}

export interface ISimplifiedMeasurementChecklist {
  measurement: SimplifiedMeasurementChecklistMeasuresDTO;
  notApplicable?: boolean;
  images?: IChecklistImageData[];
  comment?: string;
  attachments?: IAttachmentData[];
  sampling?: IMeasurementSampling;
  samplingModificationReason?: string;
}
